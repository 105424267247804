// src/components/About.js

import React from "react";
import styles from "./About.module.css";
import moi from '../images/moi2.png';

export default function About() {
  return (
    <section id="about" className = {styles.section}>

      <div className = {styles.link} >
      <a href= "https://github.com/alextube12345" >
        <img className = {styles.image}
              alt="myself" 
              src= {moi}/>
      </a>
      </div>
        
        <div className={styles.text}>
              <p>
                Hello ! I am Alexis Valencia. <br/>                
                You will find below my developement projects in relation to neutronics and criticality.
              </p>
              
         </div>
         
    </section>
  );
}


//className="object-cover object-center rounded"