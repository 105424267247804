// src/components/Projects.js

import React from "react";
import { projects } from "../data";
import styles from "./Projects.module.css";


export default function Projects() {
  return (
    <section id="projects" className={styles.projectsSection}>
      <div className={styles.containerDiv}>

        <div className={styles.AllCards}>
          {projects.map((project) => (
            <div className={styles.ProjectCard}>
              
                <div>
                <a href={project.link} key={project.image} >
                  <img className={styles.image}
                    alt="gallery"
                    src={project.image}
                    
                    
                  />
                  </a>

                  <div className={styles.text}>
                    <h1 className={styles.h1}> {project.title} </h1>
                    <h3 className={styles.h3}> {project.subtitle} </h3>
                    <p className={styles.p}>{project.description}</p>
                    <p> <a href = {project.github}>See on Github</a> </p>
                  </div>

                </div>
              
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}