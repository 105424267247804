// src/data.js
import training from './images/3dtraining.png';
import vegeta from './images/vegeta.png';
import neutronics_simulator from './images/neutronics_simulator.png';

import jiggling from './images/jiggling.png';

export const projects = [  
  {
    title: "Neutronics Simulator (C++)",
    subtitle: "A pedagogical Monte-Carlo simulator.",
    description:
      "A desktop GUI allows the user to easily modify the 2D geometry and the sources.",
    link: "/neutronics_simulator/",
    image : neutronics_simulator,
    github : "https://github.com/alextube12345/neutronics_simulator",
  },
  {
    title: "VEGETA (Javascript)",
    subtitle: " A visualizer of 3D neutronics geometries (SERPENT 2, OPENMC, MORET 5). ",
    description:
      "This web app allows the export of the 3D modelisations into 3D files (.stl or .ply eg).",
    link: "/VEGETA/",
    image: vegeta,
    github : "https://github.com/alextube12345/JddViewer",
  },
  {
    title: "3D Training VR (Javascript)",
    subtitle: "A training simulator specialized in criticality.",
    description:
      " This web app can be used with a PC or VR headset.",
    link: "/3dtraining/",
    image: training	
  },
  {
    title: "Jiggling Bubbles (Android Java)",
    subtitle: "Game where the player has to burst bubbles as fast as possible.",
    description: "",  
    link: "https://play.google.com/store/apps/details?id=fr.energiecachee.JigglingBubbles&gl=FR",
    image : jiggling,
  },
];

export const skills = ["Neutronics", "Criticality", "Reactor physics", "C++", "Python", "Web development", "Linux"];

export const testimonials = [
  {
    name: "React Reserve",
    company: "MERN Stack",
    quote:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Praesentium dolore rerum laborum iure enim sint nemo omnis voluptate exercitationem eius?"
  },
  {
    name: "React Reserve",
    company: "MERN Stack",
    quote:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Praesentium dolore rerum laborum iure enim sint nemo omnis voluptate exercitationem eius?"
  },
 ];
  
