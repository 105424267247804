// src/components/Skills.js

import React from "react";
import { skills } from "../data";
import styles from "./Skills.module.css";


export default function Skills() {
  return (
    <section id="skills" className={styles.section}>
      <div>
        <div>
          <h1> Skills &amp; Technologies </h1>
          
        </div>
        <div>
          {skills.map((skill) => (
            <div key={skill}>
              <div>
                <span> {skill} </span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}