// src/components/Contact.js

import React from "react";
import styles from "./Contact.module.css";

export default function Contact() {
  return (
    <section id="contact" className={styles.section}>
      <div>
        <h1>CONTACT</h1> <br/>
        <div className={styles.ContactContainer}>
          <div className={styles.ContactGauche}>
            <iframe 
              className = {styles.iframe}
              width="90%" 
              height="350" 
              src="https://www.openstreetmap.org/export/embed.html?bbox=2.234930126595072%2C48.764058141406885%2C2.319301693367533%2C48.79493928527828&amp;layer=mapnik" 
              >
            </iframe><br/>
            
            <small><a href="https://www.openstreetmap.org/#map=19/48.78166/2.29060">Afficher une carte plus grande</a></small>
          </div>
          <div className={styles.ContactDroite}>
              <h2> Adresse </h2>
              <p> 92330 Sceaux, FRANCE </p>
              <h2> E-mail  </h2>
              <a> alexisfx.valencia@gmail.com  </a>
          </div>

        </div>

        <br/>
        <br/>

        
        <form
          action="mailto:alexisfx.valencia@gmail.com" method="post" enctype="text/plain"
          netlify
          name="contact"
          className="lg:w-1/3 md:w-1/2 flex flex-col md:ml-auto w-full md:py-8 mt-8 md:mt-0">
          <h2 className="text-white sm:text-4xl text-3xl mb-1 font-medium title-font">
            Send me a message !
          </h2>           
          <div className="relative mb-4">
            <label htmlFor="name" className="leading-7 text-sm text-gray-400">
              Name
            </label>
            <br/>
            <input
              type="text"
              id="name"
              name="name"
              className="w-full bg-gray-800 rounded border border-gray-700 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-900 text-base outline-none text-gray-100 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
            />
          </div>
            <br/>
          <div className="relative mb-4">
            <label htmlFor="email" className="leading-7 text-sm text-gray-400">
              E-mail 
            </label>
            <br/>
            <input
              type="email"
              id="email"
              name="email"
              className="w-full bg-gray-800 rounded border border-gray-700 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-900 text-base outline-none text-gray-100 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
            />
          </div>
            <br/>
          <div className="relative mb-4">
            <label
              htmlFor="message"
              className="leading-7 text-sm text-gray-400">
              Message
            </label>
            <br/>
            <textarea
              id="message"
              name="message"
              rows="12"    
              cols="50" 
              className="w-full bg-gray-800 rounded border border-gray-700 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-900 h-32 text-base outline-none text-gray-100 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"
            />
          </div>
          <button
            type="submit"
            className="text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg">
            Send
          </button>
        </form>
        

      </div>
    </section>
  );
}