// src/components/Navbar.js

import React from "react";
import styles from "./Navbar.module.css";

export default function Navbar() {
  return (	
    
        <div className= {styles.Bar}>
          <nav className= {styles.Nav}>
            <a href="#about" className= {styles.NavItem}> Presentation </a>
            <a href="#projects" className= {styles.NavItem}> Projects </a>
            <a href="#skills" className= {styles.NavItem}> Skills </a>
            <a href="#contact" className= {styles.NavItem}> Contact </a>
          </nav>
        </div>
		

	  
  );
}